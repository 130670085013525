
import PDEDialog from '@/components/pde/PDEDialog.vue'
import PDEButton from '@/components/pde/PDEButton.vue'
export default {
  name: 'PDEConfirmationModal',

  components: {
    PDEDialog,
    PDEButton,
  },

  props: {

    dataTest: {
      type: String,
      default: 'pde-confirmation-container',
    },

    headingText: {
      type: String,
      default: 'Do you confirm the action?',
    },

    descriptionText: {
      type: String,
      default: '',
    },

    cancelButtonText: {
      type: String,
      default: 'Cancel',
    },

    confirmationButtonText: {
      type: String,
      default: 'Confirm',
    },

    horizontalPosition: {
      type: String,
      default: 'center',
      validator: (value) => ['left', 'center', 'right'].indexOf(value) !== -1,
    },

    isVisible: {
      type: Boolean,
      default: false,
    },

    verticalPosition: {
      type: String,
      default: 'bottom',
      validator: (value) => ['bottom', 'middle', 'top'].indexOf(value) !== -1,
    },

    widthClass: {
      type: String,
      default: 'max-w-632',
    },
    cancelButtonColor: {
      type: String,
      default: 'normal',
    },

  },

  emits: [
    'cancel',
    'confirm',
  ],
}
