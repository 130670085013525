
import PDEIcon from '@/components/pde/PDEIcon.vue'

export default {
  name: 'PDEIconButton',

  components: { PDEIcon },

  props: {
    styleType: {
      type: String,
      default: 'fill',
      validator: (value) =>
        ['fill', 'line', 'icon', 'label'].indexOf(value) !== -1,
    },

    color: {
      type: String,
      default: 'primary',
      validator: (value) => ['primary', 'normal'].indexOf(value) !== -1,
    },

    iconSize: {
      type: String,
      default: 'text-32',
    },

    icon: {
      type: String,
      default: '',
    },

    label: {
      type: String,
      default: '',
    },
  },

  computed: {
    isLabel() {
      return this.styleType === 'label'
    },

    getStyleClasses() {
      const styles = {
        fill: {
          primary: 'text-pureblack-80 bg-primary-100 hover:bg-primary-200 active:bg-pureblack-10 disabled:text-pureblack-20 disabled:bg-pureblack-5 h-32 w-32',
          normal: 'text-pureblack-50 bg-pureblack-5 hover:bg-pureblack-20 active:bg-pureblack-10 disabled:text-pureblack-20 disabled:bg-pureblack-5 h-32 w-32',
        },
        line: {
          primary: 'text-primary-500 bg-pureblack-0 border-1 border-solid border-primary-200 hover:bg-pureblack-15 hover:border-primary-300 active:bg-pureblack-15 active:border-pureblack-20 disabled:text-pureblack-20 disabled:bg-pureblack-5 disabled:border-pureblack-10 h-32 w-32',
          normal: 'text-pureblack-50 bg-pureblack-0 border-1 border-solid border-pureblack-15 hover:bg-pureblack-15 hover:border-pureblack-20 active:bg-pureblack-15 active:border-pureblack-20 disabled:text-pureblack-20 disabled:bg-pureblack-5 disabled:border-pureblack-10 h-32 w-32',
        },
        icon: {
          primary: 'bg-transparent text-primary-500 hover:bg-pureblack-20 active:bg-pureblack-20 disabled:text-pureblack-25 disabled:bg-transparent h-32 w-32',
          normal: 'bg-transparent text-pureblack-80 hover:bg-pureblack-20 active:bg-pureblack-20 disabled:text-pureblack-25 disabled:bg-transparent h-32 w-32',
        },
        label: {
          primary: 'bg-transparent text-primary-500 hover:text-primary-400 disabled:text-pureblack-25',
          normal: 'bg-transparent text-pureblack-80 hover:text-pureblack-50 disabled:text-pureblack-25',
        },
      }

      return styles[this.styleType][this.color]
    },
  },
}
