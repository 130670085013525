
import PDEIconButton from '@/components/pde/PDEIconButton.vue'

export default {
  name: 'PDEDialog',

  components: {
    PDEIconButton,
  },

  props: {

    dataTest: {
      type: String,
      default: 'pde-dialog-container',
    },

    positionFixed: {
      type: Boolean,
      default: false,
    },

    headingText: {
      type: String,
      default: '',
    },

    horizontalPosition: {
      type: String,
      default: 'center',
      validator: (value) => ['left', 'center', 'right'].indexOf(value) !== -1,
    },

    imageContainerShow: {
      type: Boolean,
      default: false,
    },

    imageSource: {
      type: String,
      default: 'https://',
    },

    isVisible: {
      type: Boolean,
      default: true,
    },

    modal: {
      type: Boolean,
      default: false,
    },

    showCloseButton: {
      type: Boolean,
      default: false,
    },

    verticalPosition: {
      type: String,
      default: 'bottom',
      validator: (value) => ['bottom', 'middle', 'top'].indexOf(value) !== -1,
    },

    mobileVerticalPosition: {
      type: String,
      default: '',
      validator: (value) => ['bottom', 'middle', 'top', ''].indexOf(value) !== -1,
    },

    widthClass: {
      type: String,
      default: '',
    },
  },
  emits: [
    'closeDialog',
    'dialogOverlayClicked',
  ],

  data() {
    return {
      color: this.colorSelection,
    }
  },
  computed: {
    coverPositionClass() {
      return this.positionFixed ? 'fixed' : 'absolute'
    },

    headingClass() {
      return this.modal ? 'text-21' : 'text-16'
    },

    horizontalPositionClass() {
      const horizontalPositions = {
        right: 'justify-end',
        center: 'justify-center',
        left: 'justify-start',
      }

      return horizontalPositions[this.horizontalPosition]
    },

    verticalPositionClass() {
      const verticalPositions = {
        top: 'sm:items-start',
        middle: 'sm:items-center',
        bottom: 'sm:items-end sm:bottom-24',
      }

      return verticalPositions[this.verticalPosition]
    },

    mobileVerticalPositionClass() {
      const verticalPosition = {
        top: 'items-start',
        middle: 'items-center',
        bottom: 'items-end bottom-24',
      }
      const position = this.mobileVerticalPosition ? this.mobileVerticalPosition : this.verticalPosition
      return verticalPosition[position]
    },
  },

  methods: {
    closeButtonClicked() {
      this.$emit('closeDialog')
    },
    dialogOverlayClicked() {
      this.$emit('dialogOverlayClicked')
    },
  },
}
