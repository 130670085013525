
import PDEIcon from '@/components/pde/PDEIcon.vue'
import { isTouch } from '@/helpers/navigatorInfo'

export default {
  name: 'PDETooltip',

  components: { PDEIcon },

  props: {
    disabled: {
      type: Boolean,
      default: false,
    },

    position: {
      type: String,
      default: 'right',
      validator: value => ['left', 'right', 'above', 'below'].includes(value),
    },

    showOnLoad: {
      type: Boolean,
      default: false,
    },

    text: {
      type: String,
      default: '',
    },

    variant: {
      type: String,
      default: 'simple',
      validator: value => ['simple', 'rich'].includes(value),
    },

    contentClasses: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      isVisible: false,
      isTouchScreen: isTouch(),
    }
  },

  computed: {
    positionClasses() {
      if (this.position === 'above') {
        return {
          arrow: 'bottom-0 left-1/2 transform origin-center -translate-x-1/2 translate-y-1/2',
          tooltip: 'left-1/2 -top-16 transform -translate-y-full -translate-x-1/2',
        }
      }

      if (this.position === 'below') {
        return {
          arrow: 'top-0 left-1/2 transform origin-center -translate-x-1/2 -translate-y-1/2',
          tooltip: 'left-1/2 -bottom-16 transform translate-y-full -translate-x-1/2',
        }
      }

      if (this.position === 'left') {
        return {
          arrow: 'top-1/2 right-0 transform origin-center translate-x-1/2 -translate-y-1/2',
          tooltip: '-left-16 top-1/2 transform -translate-y-1/2 -translate-x-full',
        }
      }

      return {
        arrow: 'top-1/2 left-0 transform origin-center -translate-x-1/2 -translate-y-1/2',
        tooltip: '-right-16 top-1/2 transform -translate-y-1/2 translate-x-full',
      }
    },

    showArrow() {
      return this.variant === 'rich'
    },

    showCloseButton() {
      return this.isTouchScreen
    },

    variantClasses() {
      const variants = {
        simple: {
          button: 'ml-6',
          content: 'flex bg-black-800 text-pureblack-0 px-4 pt-1 pb-2 whitespace-nowrap rounded-3',
          tooltip: 'text-12 rounded-3',
        },
        rich: {
          button: 'absolute top-8 right-8',
          content: 'relative z-1 bg-pureblack-0 px-24 py-12 rounded-4',
          tooltip: 'border-pureblack-20 border-1 border-solid rounded-4 shadow-24 text-14',
        },
      }

      return variants[this.variant]
    },
  },

  beforeMount() {
    if (this.showOnLoad) {
      this.isVisible = true
    }
  },

  methods: {
    hoverHandler({ type }) {
      this.isVisible = type === 'mouseenter'
    },
  },
}
